.horizontal-slider {
    width: 100%;
    height: 50px;
}

.horizontal-slider .example-track {
    top: 20px;
    height: 10px;
}

.example-track.example-track-0 {
    background: #2A49F9;
}

.example-track.example-track-1 {
    background: grey;
}

.example-track.example-track-2 {
    background: grey;
}

.horizontal-slider .example-thumb {
    top: 1px;
    width: 50px;
    height: 48px;
    line-height: 38px;
    border-radius: 50%;
    font-size: 0.9em;
    text-align: center;
    background-color:#2A49F9;
    color: white;
    cursor: pointer;
    border: 5px solid #fff;
    box-sizing: border-box;
}